import { Container, Row, Col } from "react-bootstrap";
import Header from "../../BlockComponents/Header/Header";
import Footer from "../../BlockComponents/Footer/Footer";
import { Link } from "react-router-dom";
import Template from "./KTAML-Template.docx";

function SubmissionPage() {
  return (
    <>
      <Header />
      <Container
        style={{
          textAlign: "left",
          margin: "0 1.5rem",
          paddingBottom: "15rem",
          width: "90%"
        }}
      >
        <Row>
          <Col>
            <h2 className="section-heading m-0">Submission</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              The proposed Manuscript should follow the recommended format KTAML
              Template&nbsp;
              <span>
                <Link
                  to={Template}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  (.docx)
                </Link>
                 &nbsp;or&nbsp;
              </span>
              <span>
                <Link
                  to={Template}
                  download="KTAML Template"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  (.doc)&nbsp;
                </Link>
              </span>
              strictly abiding the author guidelines.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              The submitted manuscripts should be plagiarism free and might not
              be published previously elsewhere. Manuscripts are to be submitted
              in MS Word format (.doc/.docx) to E Mail:{" "}
              <a href="mailto:research@decisiontreekt.com">
                research@decisiontreekt.com
              </a>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SubmissionPage;
