import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import journalImage from "./journal-cover.png";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 101,
    name: "Recognition of Retinopathy Images Through Bagging Ensemble with Homogeneous Tree-Based Classifiers",
    pageNumber: "1-8",
    authorData: [
      {
        name: "Yunli Lee,",
        designation: "Associate Professor, ",
        department: "Department of Computing and Information Systems,",
        university:
          "School of Engineering and Technology, Sunway University, Malaysia.",
      },
      {
        name: "Siew Fan Wong,",
        designation: "Associate Professor",
        department: "Taylor’s Business School, ",
        university: "Taylor’s University, Malaysia.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.01.01",
    keywords:
      "Bagging, Diabetic Retinopathy (DR), Homogeneous base Classifiers, Ensemble, KNN, IBK.",
    abstract:
      "One of the most significance consequences of Diabetes is Retinopathy is affect the eye rapidly before an ophthalmologist starts is intensive treatment to support DR prognosis researchers in Machine learning try to construct solutions through Image Processing and Deep learning. A stream of research’s recently has been investigated which includes the authors tried to explore the existence of more accurate classifiers either in the form of individual supervised learner or as part of ensembles. In this article such a frame work is proposed with image inputs available from a public data repository. The Retinal Fundus images for diabetic retinopathy are usually error-prone due to noise and redundancy in them. This work mainly aims image mining with many bagging ensembles with varieties of base classifiers trees and instance based learners the performance of such an experiment achieves maximum accuracy 82.08% this performance decision made by the physician may be supported.",
    published: "20-03-2023",
    source: "Vol 01. Issue. 01 Mar (2023)",
    cite: "Yunli Lee, Siew Fan Wong, “Recognition of Retinopathy Images Through Bagging Ensemble with Homogeneous Tree-Based Classifiers”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 01, pp. 1–8, Mar. 2023.",
    imageURL: journalImage,
  },
  {
    id: 102,
    name: "On Possibilities of Learning the Meta Data for Machine Learning Algorithms",
    pageNumber: "9-18",
    authorData: [
      {
        name: "Venkatesh Naganathan,",
        designation: "Academic Director,",
        department: "Amity Global Institute,",
        university: "Singapore.",
      },
      {
        name: "N. Muralidharan",
        designation: "Professor,",
        department: "Department of Electronics,",
        university: "PSB Academy, Singapore.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.01.02",
    keywords:
      "Meta data, Root mean squared error, Machine Learning Mean absolute error, Correlation coefficient.",
    abstract:
      "Learning the learners improves the searching for the optimal methods in the journey of knowledge discovery. Meta classifiers in the standard form denote variation of classifier types and improvement of performance and give the opportunity for systematic search of experiments based on integrating the results but lacking tracing out the best set of parameters of the algorithms. In this article, we propose an extended framework for   meta data on machine learning algorithms and accommodating the corresponding parameters as instances. Further it is presented the design of collection of such meta data and iterations over such level of data. Each instance is the mapping between the input, selected ‘contextual machine algorithm’ and the output, error amount while handling the algorithm instance.  Meta-level learning is a machine learning approach that uses a meta-model to make predictions about the performance of base models on new data. In the context of the previous project as point of reference, the instances in the meta data would likely have been the results of applying various         classification algorithms to a particular dataset. The meta-model would then have been trained on this meta data to predict the performance of each classification algorithm on future datasets. The main aim of this article would have been to improve the accuracy and efficiency of choosing the right classification algorithm for a particular problem, without having to train each algorithm on the data.  The results based on the new framework shows the estimates for least errors by regression classifiers are obtained: (i) Gaussian Processes with 744.8285. Root mean squared error (ii) support vector      machine optimization (SMO reg) with 93.782Mean absolute error. It establishes one of the mechanisms towards master algorithm for which researchers in ML community thriving for.",
    published: "20-03-2023",
    source: "Vol 01. Issue. 01 Mar (2023)",
    cite: "Venkatesh Naganathan, N. Muralidharan, “On Possibilities of Learning the Meta Data for Machine Learning Algorithms”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 01, pp. 9–18, Mar. 2023.",
    imageURL: journalImage,
  },
  {
    id: 103,
    name: "Application of machine learning for the association of Climate Risk and Economic Losses",
    pageNumber: "19-29",
    authorData: [
      {
        name: "Faizal Hajamohideen,",
        designation:
          "Assistant Professor, Department of Information Technology,",
        department: "University of Technology and Applied Sciences, ",
        university: "Sultanate of Oman",
      },
      {
        name: "Raida Said Salim Al Abaidani",
        designation:
          "Assistant Professor, Department of Information Technology,",
        department: "University of Technology and Applied Sciences, ",
        university: "Sultanate of Oman",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.01.03",
    keywords:
      "GDP, Climate risk, correlation, Linear Regression, Support Vector Machine, k-nearest neighbourhood, Gaussian Processes.",
    abstract:
      "We address the problem of finding the association of natural and social attributes by selecting the appropriate classifier from the family of regression models. In this   category the attributes in the natural phenomena is climate risk and in the social side, economic losses are considered. In order to react quickly the machine learning community has been endowed with the weapons for wiping out the error or loss in the mentioned association and most of the time missing the target. The most social relevant application is the use case here and the performance for achieving the minimum loss is presented. There is a chaotic phenomenon present in the climate losses and measure its correlation to the economic conditions of the countries.",
    published: "20-03-2023",
    source: "Vol 01. Issue. 01 Mar (2023)",
    cite: "Faizal Hajamohideen, Raida Said Salim Al Abaidani, “Application of machine learning for the association of Climate Risk and Economic Losses”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 01, pp. 19–29, Mar. 2023.",
    imageURL: journalImage,
  },
  {
    id: 104,
    name: "Ontology learning based on Machine Learning over Knowledge Bases",
    pageNumber: "30-42",
    authorData: [
      {
        name: "Shabana Shafi,",
        designation: "Assistant Professor,",
        department: "Department of Computer Science, Taibah University",
        university: "Al Madinah, Saudi Arabia.",
      },
      {
        name: "Ishfaq Gujree",
        designation:
          "State Key Laboratory of Tibetan Plateau Earth system, resource and environment (TPESRE),",
        department:
          "Institute of Tibetan plateau research, Chinese academy of sciences,",
        university: "Beijing 100101. China",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.01.04",
    keywords:
      "Ontology, Regression, Support Vector Machine, Multilayer Perceptron, knowledge Base.",
    abstract:
      "The applications of ontology are mainly revolve around traditionally inference or symbolic reasoning. They are not taken by deep learners for usual prediction or classification exercises. In this paper a small ontology of ‘wines’ is considered to establish the link between knowledge bases and machine learning paradigms. All along the research community has been handling this with mostly learners. The main aim of this paper is to verify this specific ontology to be trained and tested with the standard classifiers. The possibilities are foreseen as a result of these approaches covered here and recommendations are made for further incremental accomplishments in this type of learning.",
    published: "20-03-2023",
    source: "Vol 01. Issue. 01 Mar (2023)",
    cite: "Shabana Shafi, Ishfaq Gujree, “Ontology learning based on Machine Learning over Knowledge Bases”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 01, pp. 30–42, Mar. 2023.",
    imageURL: journalImage,
  },
  {
    id: 105,
    name: "Recommendations On Self-Driven Entrepreneurship with Computational Intelligence",
    author: "George Michael, KalaiSelvi P",
    pageNumber: "43-55",
    authorData: [
      {
        name: "George Michael,",
        designation: "Professor, Department of ADS",
        department: "Saveetha Institute of Medical and Technical Sciences,",
        university: "Chennai.",
      },
      {
        name: "KalaiSelvi",
        designation: "Research Scholar,",
        department: "Department of Computer Science and Engineering,",
        university:
          "Saveetha Institute of Medical and Technical Sciences, Chennai.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.01.05",
    keywords:
      "Computational Intelligence, Entrepreneurship, Reliability, SPSS, Correlation, Covariance, Model Fitting. Recursive.",
    abstract:
      "Self-driven entrepreneurship attracts individuals who start and run their own businesses without the need for external guidance or funding. In general, the entrepreneurs are motivated by a desire to be their own bosses and to control their own destinies. Since they often have a clear vision of what they want to achieve, they are willing to take risks to make it happen. Researchers have not yet given their full scope on applying computational intelligence (CI) for effective usage of common characteristics of        self-driven entrepreneurs like being highly motivated, having a strong work ethic, and being willing to learn from their mistakes. In order to optimize or converge, in this article some significant aspects are considered as feasible candidates and performed random simulation showing model fit with the connected parameters followed by a set of        recommendations for future possibilities. The primary objective of this article is demonstrate the links between entrepreneurship and CI technical projects, through    simulation using IBM SPSS data analytical tool.",
    published: "20-03-2023",
    source: "Vol 01. Issue. 01 Mar (2023)",
    cite: "George Michael, Kalaiselvi, “Recommendations on Self-Driven Entrepreneurship with Computational Intelligence”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 01, pp. 43–55, Mar. 2023.",
    imageURL: journalImage,
  },
];

function ArchivedIssues1() {
  const id = 1;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 1,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 2,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 3,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
  ];

  return (
    <>
      <Header idPath="true" />
      <Container>
        <Row className="pb-2">
          <h3 className="section-heading">{archiveData[id - 1].volumeNumber}</h3>
        </Row>
        <Row className="pb-4">
          <Col lg={3}>
            <Link to="/">
              <img
                src={journalImage}
                alt="Journal Thumbnail"
                className="journal-thumbnail"
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
            <p><span style={{fontWeight: "bold"}}>PUBLISHED:</span> 2023-03-20</p>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 pb-5">
        <Row>
          <Col>
            <h2 className="sub-heading m-0">Papers</h2>
            <hr className="heading-line" />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className="flex-style paper-info"
                  rel="noopener noreferrer"
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className="paper-author"
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className="flex-style py-2"
                  rel="noopener noreferrer"
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className="download-button">
                    <Button
                      variant="secondary"
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=""
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className="flex-style"
                style={{ alignItems: "start" }}
              >
                <p key={i} className="page-number">
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues1;
