import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";
import Accordion from "react-bootstrap/Accordion";
import "./Authors.css";

function AuthorsPage() {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <h2 className="section-heading" style={{ textAlign: "center" }}>
              Editorial board
            </h2>
          </Col>
        </Row>
        <Row className="pt-3 pb-5">
          <Col>
            <Accordion defaultActiveKey={["0", "1", "2"]}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Editor-in-Chief</Accordion.Header>
                <Accordion.Body>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      Dr. A.Kumaravel
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Bharath Institute of Higher Education and Research, India.
                    </Col>
                  </Row>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      E-mail:{" "}
                      <a
                        href="mailto:editor@decisiontreekt.com"
                        style={{
                          color: "#333",
                          textDecoration: "none",
                          fontWeight: "normal",
                        }}
                      >
                        editor@decisiontreekt.com
                      </a>
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Director, Decision Tree PTE. LTD. Singapore.
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Associate Editors</Accordion.Header>
                <Accordion.Body>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      Dr. T.Vijayan
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Bharath Institute of Higher Education and Research, India.
                    </Col>
                  </Row>
                  <Row className="author-row alt-row">
                    <Col lg={4} className="author-name">
                      Dr. Parasuraman Padmanabhan
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Nanyang Technological University, Singapore
                    </Col>
                  </Row>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      Dr. Matthew Teow
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Sunway University, Kuala Lumpur, Malaysia.
                    </Col>
                  </Row>
                  <Row className="author-row alt-row">
                    <Col lg={4} className="author-name">
                      Mr. Muthukumaran Maruthappa
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Sunway University, Kuala Lumpur, Malaysia
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Editorial Advisory Board</Accordion.Header>
                <Accordion.Body>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      Dr. M.Ponnavaikko
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Provost, Bharath Institute of Higher Education and
                      research, India.
                    </Col>
                  </Row>
                  <Row className="author-row alt-row">
                    <Col lg={4} className="author-name">
                      Dr. K.Lal Kishore
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Former Vice Chancellor, JNT university, Anantapur, India
                    </Col>
                  </Row>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      Dr. Thamarai Selvi Somasundaram{" "}
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Registrar, Carolina University, USA
                    </Col>
                  </Row>
                  <Row className="author-row alt-row">
                    <Col lg={4} className="author-name">
                      Dr. Ponnammal Natarajan
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      Former Dean(Academic Research), Anna University, India
                    </Col>
                  </Row>
                  <Row className="author-row">
                    <Col lg={4} className="author-name">
                      Dr. C.Shoba Bindu
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      JNTUA College of Engineering, India
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default AuthorsPage;
