import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 121,
    name: "Evaluating the relationship between Diabetes and Cancer using Rough Set Theory",
    pageNumber: "1-7",
    authorData: [
      {
        name: "M. Sudha,",
        designation: "Research Scholar,",
        department: "Department of Mathematics,",
        university: "AMET University, Chennai, India.",
      },
      {
        name: "S. Mehertaj",
        designation: "Assistant Professor,",
        department: "Department of Mathematics,",
        university: "AMET University, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.02.01",
    keywords: "Breast cancer, Diabetes, Rough set theory, decision rules.",
    abstract:
      "This paper intends to identify the association between diabetes and breast cancer by the indication of the prospects of the Rough Set theory. Pawlak (1982) proposed the Rough set theory, a new mathematical method for inadequate facts. Without supplementary information about data, the rough set theory can easily find the minimal set using approximations which is the main advantage to get the information pattern from the big data. It makes available a proficient LEMS system for abstract in gruels from the reduction set to deduct the decisions. To excerpt decision rules commencing the Brest cancer data set, the core set was deducted using the Rough set-based tool. The outcomes of this study offer the associated findings between and the compact rules of rough set crack the information shows the relation amongst diabetes and cancer which may influence the treatment process of cancer diagnosis.",
    published: "2024-03-20",
    source: "Vol.02, Issue. 02, Mar(2024)",
    cite: "M. Sudha, S. Mehertaj, “Evaluating the relationship between Diabetes and Cancer using Rough Set Theory”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 02, pp. 1–7, Mar. 2024. DOI: https://doi.org/10.59567/ktAML.V2.02.01",
  },
  {
    id: 122,
    name: "Fuzzy Unordered Rule Induction Algorithm for Improved Classification of Severe Instances in Kidney Disease Diagnosis",
    pageNumber: "08-14",
    authorData: [
      {
        name: "Faizal Hajamohideen,",
        designation: "Assistant Professor,",
        university:
          "University of Technology and Applied Sciences, Sultanate of Oman.",
      },
      {
        name: "Raida Said Salim Al Abaidani",
        designation: "Assistant Professor,",
        university:
          "University of Technology and Applied Sciences, Sultanate of Oman.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.02.02",
    keywords:
      "Fuzzy Unordered Rule Induction Algorithm (FURIA), Kidney Disease, Severe Instances, Gaussian Membership Functions, Diagnostic Precision, Sensitivity, Nephrology, Healthcare, Machine Learning.",
    abstract:
      "This research addresses the critical challenge of accurate diagnosis of kidney disease using the Fuzzy Unordered Rule Induction Algorithm (FURIA). The objectives of this study are twofold: first, to develop a novel approach leveraging FURIA models with a heightened sensitivity to severe instances in kidney disease datasets; and second, to evaluate the impact of varying the 'N' parameter, controlling the minimum weights of instances within splits, on the overall performance of the model. Motivated by the paramount importance of early and accurate detection of severe cases of kidney disease, our research proposes a methodology that integrates Gaussian membership functions tailored to the characteristics of specific kidney disease datasets. The background of the study emphasizes the significance of robust diagnostic tools in nephrology and the potential of fuzzy rule-based systems to enhance sensitivity to severe instances. Preliminary results demonstrate promising outcomes, suggesting that the proposed FURIA-KD framework effectively identifies severe cases, paving the way for improved diagnostic precision and patient outcomes in kidney disease.",
    published: "2024-03-20",
    source: "Vol.02, Issue. 02, Mar(2024)",
    cite: "Faizal Hajamohideen, Raida Said Salim Al Abaidani, “Fuzzy Unordered Rule Induction Algorithm for Improved Classification of Severe Instances in Kidney Disease Diagnosis”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 02, pp. 08–14, Mar. 2024. DOI: https://doi.org/10.59567/ktAML.V2.02.02",
  },
  {
    id: 123,
    name: "SEM for exploring pragmatism applied to context of business decision making",
    pageNumber: "15-24",
    authorData: [
      {
        name: "Kalaiselvi B,",
        designation: "Associate Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "Bharath Institute of Higher Education and Research, Chennai, India.",
      },
      {
        name: "Venkateshan",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering, ",
        university:
          "Bharath Institute of Higher Education and Research, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.02.03",
    keywords:
      "Scientific research, IBM SPSS, AMOS, Path Analysis, Pragmatism, Business decision Making.",
    abstract:
      "Pragmatism, as a philosophical perspective, emphasizes practical consequences and real-world outcomes in decision-making processes. In the context of business, pragmatism advocates for solutions that are not only theoretically sound but also feasible and effective in practice. This approach underscores the importance of flexibility, adaptability, and responsiveness to changing circumstances. In essence, pragmatism encourages decision-makers to prioritize results over rigid adherence to preconceived notions or theoretical constructs. SEM provides a methodological framework that aligns well with the principles of pragmatism. By allowing for the simultaneous examination of multiple relationships and variables, SEM enables researchers and decision-makers to construct comprehensive models that capture the complexity of real-world scenarios. Moreover, SEM facilitates the integration of both observed and latent variables, thereby accommodating the nuanced and multifaceted nature of business phenomena. IBM SPSS Amos software aids in conducting structural equation modeling, facilitating the application of standard multivariate techniques such as correlation analysis and covariance analysis. These three constructs are established through the use of questionnaires. A positive correlation indicates a more robust relationship. A company is deliberating between two manufacturing processes for a new product line. Decision-making in manufacturing, especially when choosing between two manufacturing processes for a new product line, is intricate and involves various factors.",
    published: "2024-03-20",
    source: "Vol.02, Issue. 02, Mar(2024)",
    cite: "Kalaiselvi B, Venkateshan, “SEM for exploring pragmatism applied to context of business decision making”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 02, pp. 15-24, Jan. 2024. DOI: https://doi.org/10.59567/ktAML.V2.02.03",
  },
];

function ArchivedIssues6() {
  const id = 7;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
    {
      id: 105,
      imageName: "journal-cover-issue5.png",
      volumeNumber: "Vol. 01, Issue. 05, Nov (2023)",
    },
    {
      id: 106,
      imageName: "journal2-issue1.png",
      volumeNumber: "Vol.02, Issue 01, Jan(2024)",
    },
    {
      id: 107,
      imageName: "journal2-issue2.png",
      volumeNumber: "Vol.02, Issue. 02, Mar(2024)",
    },
  ];

  return (
    <>
      <Header idPath='true' />
      <Container>
        <Row className='pb-2'>
          <h3 className='section-heading'>
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className='pb-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src={`${basePath}/images/journal2-issue2.png`}
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2024-03-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='pt-3 pb-5'>
        <Row>
          <Col>
            <h2 className='sub-heading m-0'>Papers</h2>
            <hr className='heading-line' />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className='flex-style paper-info'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className='paper-author'
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className='flex-style py-2'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className='download-button'>
                    <Button
                      variant='secondary'
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=''
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className='flex-style'
                style={{ alignItems: "start" }}
              >
                <p key={i} className='page-number'>
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues6;
