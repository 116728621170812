import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 118,
    name: "Decoding Diagnostic Precision: Evaluating Error Implications with Random Forest and Support Vector Regression Machine Learning Models",
    pageNumber: "1-8",
    authorData: [
      {
        name: "Rajagopal R,",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "RMK College of Engineering and Technology, Chennai, India.",
      },
      {
        name: "Sadasivam S",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university: "KCG College of Technology, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.01.01",
    keywords:
      "Diagnostic complexity, Diagnostic constraints, Cognitive Biases, Diagnostic disconnect, Random Forest and Support Vector Machines.",
    abstract:
      "Precision in medical engineering diagnosis is crucial for effective treatment and optimal patient care, as inaccuracies can result in prolonged discomfort, unnecessary interventions, and, in extreme cases, fatalities. This study aims to predict the impact of key factors contributing to misdiagnosis using Machine Learning (ML) algorithms. Random Forest Regression (RFR) and Support Vector Regression (SVR) are employed in the process of misdiagnosis level prediction. This research work contributes to the evolving landscape of medical diagnostics by providing a data-driven understanding of the nuanced relationships between various contributing factors and the potential implications for diagnostic accuracy. The research findings indicate that RFR outperformed SVR, showcasing a higher coefficient of determination (r2) of 0.97 and a lower Root Mean Square Error (RMSE) of 0.2. This suggests that RFR may offer superior predictive accuracy in assessing misdiagnosis risk associated with the identified factors. By exploring the influence of Complex Medical Conditions, Limited Time and Resources, Cognitive Biases, and Inadequate Communication, this research contributes to refining diagnostic practices in medical engineering. The insights gained from our study have the potential to inform strategies for minimizing misdiagnosis-related complications, ultimately enhancing patient outcomes and the overall quality of healthcare delivery.",
    published: "2024-01-20",
    source: "Vol 02. Issue. 01 Jan (2024)",
    cite: "Rajagopal R, Sadasivam S, “Decoding Diagnostic Precision: Evaluating Error Implications with Random Forest and Support Vector Regression Machine Learning Models”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 01, pp. 1–8, Jan. 2024. DOI: https://doi.org/10.59567/ktAML.V2.01.01",
  },
  {
    id: 119,
    name: "Evaluating Decision Tree and Rule-Based Models in Arterial Blood Analysis",
    pageNumber: "09-16",
    authorData: [
      {
        name: "K. Prabhu,",
        designation: "Associate Professor,",
        university: "Sree Balaji Medical College and Hospital, Chennai, India.",
      },
      {
        name: "M R K. Rao",
        designation: "Professor,",
        university: "Sree Balaji Medical College and Hospital, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.01.02",
    keywords:
      "Arterial Blood Gas, RIPPER, J48, PH, PaO2, Oxygen Partial Pressure, Precision, Recall, Accuracy",
    abstract:
      "This technical article delves into the realm of medical diagnostics, specifically the analysis of blood parameters, employing two distinct machine learning models namely the Decision Tree and the Rule-Based Model (RIPPER). The aim is to scrutinize and compare the performance of these models across varying test sizes. The study focuses on attributes related to arterial blood gas (ABG) analysis, shedding light on their potential in medical decision-making. JRip classifier appears to be a robust and effective model for the given classification task. One can conclude the consideration of using a 90:10 training and testing ratio for optimal per-formance, but be aware that even at lower ratios, the classifier maintains relatively high accuracy and balanced precision-recall values.",
    published: "2024-01-20",
    source: "Vol 02. Issue. 01 Jan (2024)",
    cite: "K. Prabhu, M R K. Rao, “Evaluating Decision Tree and Rule-Based Models in Arterial Blood Analysis”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 01, pp. 09–16, Jan. 2024. DOI: https://doi.org/10.59567/ktAML.V2.01.02",
  },
  {
    id: 120,
    name: "Structural Equation Modelling applied for validation of drug's effectiveness in medical diagnosis Augmented with possible  skepticism",
    pageNumber: "17-27",
    authorData: [
      {
        name: "Arti Avinash Tekade,",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "Pimpri Chinchwad College of Engineering and Research, Pune, India.",
      },
      {
        name: "Maithili Shailesh Andhare",
        designation: "Associate Professor,",
        department: "Department of Electronics and Communication Engineering, ",
        university:
          "Pimpri Chinchwad College of Engineering and Research, Pune, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.01.03",
    keywords:
      "Skepticism, Questioning Evidence (QE), Demanding Rigorous Testing (DRT), SPSS, AMOS, Path Analysis.",
    abstract:
      "The most common thyroid cancer or endocrine Malignancy is deadly increasing all over the world. Ultrasound imaging stands as a promising diagnostic method for early thyroid nodule examined. A computer-based diagnostic system has emerged to distinguish between nonthreatening and malicious thyroid nodules. The classifier's inadequacy correlates with the number of features utilized. This article introduces a multi-channel technique involving a convolutional neural network and a feature cropping network. The trending method like regularized leader best deep neural network in Weka 3.85 using deep learning 4J method. We introduce an innovative a carefully designed deep neural network architecture with intricate regularization of the loss function and fine-tuned network hyper parameters, aimed at achieving nodule detection without the need for intricate post-processing refinements. Our local training and validation datasets encompass 3,538 ultrasound frames gathered from 60 and 20 patients, respectively, exhibiting significant variability. The vital part of our approach lies in a deep learning framework rooted in the multi-task model Mask CNN. We have devised a regularization enriched loss function that places a higher emphasis on detection as opposed to segmentation. To assess its efficacy, we conducted validation on 700 UV images from patients. Our model is capable of identifying various types of thyroid nodules. The experimental findings affirm the effectiveness of our proposed method for thyroid nodule detection.",
    published: "2024-01-20",
    source: "Vol 02. Issue. 01 Jan (2024)",
    cite: "Arti Avinash Tekade, Maithili Shailesh Andhare, “Structural Equation Modelling applied for validation of drug's effectiveness in medical diagnosis Augmented with possible  skepticism”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 01, pp. 17-27, Jan. 2024. DOI: https://doi.org/10.59567/ktAML.V2.01.03",
  },
];

function ArchivedIssues6() {
  const id = 6;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
    {
      id: 105,
      imageName: "journal-cover-issue5.png",
      volumeNumber: "Vol. 01, Issue. 05, Nov (2023)",
    },
    {
      id: 106,
      imageName: "journal2-issue1.png",
      volumeNumber: "Vol.02, Issue 01, Jan(2024)",
    },
  ];

  return (
    <>
      <Header idPath='true' />
      <Container>
        <Row className='pb-2'>
          <h3 className='section-heading'>
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className='pb-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src={`${basePath}/images/journal2-issue1.png`}
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2024-01-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='pt-3 pb-5'>
        <Row>
          <Col>
            <h2 className='sub-heading m-0'>Papers</h2>
            <hr className='heading-line' />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className='flex-style paper-info'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className='paper-author'
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className='flex-style py-2'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className='download-button'>
                    <Button
                      variant='secondary'
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=''
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className='flex-style'
                style={{ alignItems: "start" }}
              >
                <p key={i} className='page-number'>
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues6;
