import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

function GuidelinesForAuthors() {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <h2 className='section-heading m-0'>Guidelines for Authors</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              As an international, peer-refereed journal, Knowledge Transactions
              on Applied Machine Learning provides a platform for the
              publication of the most advanced real time, scientific research in
              the areas of machine learning technology involving model
              construction, development, deployment and distribution in
              multi-disciplinary applications. The journal seeks and encourages
              original empirical explorations.
            </p>
            <p>
              For manuscripts with a modern or meticulous methodology
              subdivision, ancillary on-line material outlining the methodology
              is welcome (e.g.,data-description, derivations, syntax code, data
              samples). The articles may represent a variety of theoretical
              perspectives and different methodological approaches. They may
              refer to any research level, from post graduate and to a diversity
              of research scholars in machine learning and transfer knowledge
              settings, from university classroom to lab facilities. They may
              depend on the full spectrum of established methodologies, from
              laboratory experiments to field observations. Rigor of work may
              consist of Empirical research designs stating the hierarchical
              structure of the data and explicitly state how they have addressed
              the mapping structure in training, testing and validating.
              Previous publications based on the same idea using the same or
              similar measures must be acknowledged.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <h2 className='section-heading m-0'>Article Processing Charges</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul>
              <li>
                Full papers: 50 USD +Tax– 12-16 pages (+5 USD/each additional
                page)
              </li>
              <li>Short Papers: 40 USD + Tax– 4-8 pages</li>
            </ul>
            <p>
              Corresponding author of accepted articles will be sent invoice to
              make the payment in USD.
            </p>
            <p className='pb-5'>
              <strong>*These Charges are waived up to Jan 31, 2024.</strong>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default GuidelinesForAuthors;
