import "./Scope.css";
import Header from "../../BlockComponents/Header/Header";
import Footer from "../../BlockComponents/Footer/Footer";
import { Container, Row, Col } from "react-bootstrap";

function ScopePage() {
  const scopeList = [
    [
      "Machine learning",
      "Artificial intelligence",
      "Classification ",
      "Regression",
      "Clustering",
      "Data mining",
      "Reinforcement learning",
      "Problem solving and planning",
      "Knowledge representation",
      "Feature selection",
      "Neural network",
      "Evaluation",
      "Measurement Sensors",
      "Audio",
      "Video",
      "Streams",
    ],
    [
      "Big Data",
      "Natural language processing",
      "Web mining",
      "Text mining",
      "Scientific discovery",
      "Design and diagnosis",
      "Game theoretic learning",
      "Graphical models",
      "Robotics and control",
      "Transfer learning",
      "Deep Learning",
      "Vision and speech perception",
      "Independent component analysis",
      "Inductive logic programming",
      "Uncertainty",
      "Kernel methods",
    ],
    [
      "Knowledge extraction",
      "Probabilistic networks and statistical models",
      "Model choice",
      "Nonparametric methods",
      "Online learning",
      "Optimization",
      "Relational learning",
      "Robustness",
      "Spectral methods",
      "Network and graph-based machine learning",
      "Information retrieval",
      "Entropy and machine learning applications",
      "Statistical learning theory",
      "Visualization",
      "Geometric machine learning and topology",
      "Dimensionality reduction",
    ],
  ];
  return (
    <>
      <Header />
      <Container>
        <Row>
          <h2 className="section-heading">Scope</h2>
        </Row>
        <Row>
          <p style={{lineHeight: "2"}}>
            Articles are artefacts, expected to be promoting strong pillars for
            machines to learn the requirements in real life and are based on
            methodologies in Symbolic, Logic, Inferential, Statistical and
            Evolutionary principles.
          </p>
          <p>The knowledge areas lie in the scope are
            not limited to the following subjects :-</p>
        </Row>
        <Row className="pt-3 pb-5">
          <Col className="scope-list scope-row-one">
            {scopeList[0].map((scope, i) => (
              <li className={i % 2 === 0 ? "even" : "odd"}>{scope}</li>
            ))}
          </Col>
          <Col className="scope-list scope-row-two">
            {scopeList[1].map((scope, i) => (
              <li className={i % 2 === 0 ? "even" : "odd"}>{scope}</li>
            ))}
          </Col>
          <Col className="scope-list scope-row-three">
            {scopeList[2].map((scope, i) => (
              <li className={i % 2 === 0 ? "even" : "odd"}>{scope}</li>
            ))}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default ScopePage;
