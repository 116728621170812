import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [    
  {
    id: 106,
    name: "On Predictability of Performance at Outcome Based Education focusing on Course Outcomes",
    pageNumber: "1-14",
    authorData: [
      {
        name: "K. Sivachandar,",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university: "RMK College of Engineering and Technology, India.",
      },
      {
        name: "G. Gulothungan",
        designation: "Assistant Professor",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.02.01",
    keywords:
      "Course outcomes, Program outcomes, Classification, Supervised Learning, Ensemble, Bagging, Boosting, Random Tree, Random committee, Random Seed.",
    abstract:
      "Performance-based education is an ever-prolonging high expectation to reform the learning outcomes of the global education system starting from the traditional Indian ‘Gurukulum’ system to the digital online system. In outcome-based education (OBE), a “design down” process is employed which moves from program outcomes (POs) to Course Outcomes (COs) and outcomes for individual learning experiences. Course Outcomes determine the performance indicators and capacities reflected in the course contents. Hence stakeholders are encouraged to be familiar with these OBE metrics and they are somehow. For the first time in history, Washington Accord (WA) recommended Outcome-based education (OBE) systems on a global level. The objective of the proposed work is to implement the classification model for Course outcome (CO) attainment using machine learning algorithms based on the marks in the assessment either direct assessment like the Internal assessments, Assignments, Final      Semester Exams, and indirect assessment by student feedback. The parameters or thresholds are aligned/ tuned by the course coordinators. The proposed framework for CO attainment predictability is to achieve an overall maximum accuracy level of 99.09 % by the Random Committee classifier and for the Bagging ensemble.",
    published: "2023-05-20",
    source: "Vol 01. Issue. 02 May (2023)",
    cite: "K. Sivachandar, G. Gulothungan, “On Predictability of Performance at Outcome Based Education focusing on Course Outcomes”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 02, pp. 1–14, May 2023.",
  },
  {
    id: 107,
    name: "Implementation of Graph Embedding for Reduction of Multi-Drug Effects",
    pageNumber: "18-29",
    authorData: [
      {
        name: "Raushni Jaiswal,",
        designation: "",
        department: "Department of Information and Technology",
        university:
          "Bharath Institute of Higher Education and Research, India.",
      },
      {
        name: "Annamalai S,",
        designation: "",
        department: "Department of Information and Technology",
        university:
          "Bharath Institute of Higher Education and Research, India.",
      },
      {
        name: "Saivedavyas K",
        designation: "",
        department: "Department of Information and Technology,",
        university:
          "Bharath Institute of Higher Education and Research, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.02.02",
    keywords:
      "Graph Embedding, Drug-drug interactions, support vector machines, CNN",
    abstract:
      "All along in the medicine history it has been a growing prevalence of multi-drug therapies, particularly among elderly patients with multiple ailments. However, the use of multiple drugs can lead to unexpected drug-drug interactions (DDIs), which can cause adverse reactions or even life-threatening toxicity, posing a significant risk to patients. As the demand for multi-drug treatments continues to rise, it has become increasingly important to identify potential DDIs. However, the detection of DDIs in a large number of drug pairs, both in-vitro and in-vivo, is a laborious and expensive process. In this article, these DDI are augmented with side effects as labels so that node prediction or link prediction supports physicians to go for more comfort zone for their fruitful decisions. The fight against multi-drug-resistant infections has intensified the search for effective strategies for improving drug discovery and personalized medical treatment. Graph embedding of molecular structures is a promising approach for reducing multi-drug resistance and improving patient outcomes. This technique represents molecular structures as graphs to identify patterns of drug resistance and predict drug efficacy. Challenges in data quality and technique selection need to be addressed. The research community is actively engaged in developing new algorithms and integrating them with other machine-learning techniques. Graph embedding holds potential for personalized medicine and discovering novel drug combinations. It can identify key nodes and edges that contribute to drug resistance, leading to the development of more effective treatments. It can also optimize drug combinations and predict drug interactions, reducing clinical trial time and cost. Ongoing research holds great potential for unlocking new avenues for drug discovery and personalized medicine. An accuracy of 81% is achieved using a proposed framework with SVM polynomial, RBF, and sigmoid kernel.",
    published: "2023-05-20",
    source: "Vol 01. Issue. 02 May (2023)",
    cite: "Raushni jaiswal, Annamalai S , Saivedavyas K , “Implementation of Graph Embedding for Reduction of Multi-Drug Effects”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 02, pp. 15–25, May 2023.",
  },
  {
    id: 108,
    name: "Techniques Involved in Forensic Evidence Collections: A Short Communication",
    pageNumber: "26-33",
    authorData: [
      {
        name: "Raida Said Salim Al Abaidani,",
        designation: "Assistant Professor,",
        department: "Department of Information and Technology",
        university:
          "University of Technology and Applied Sciences, Sultanate of Oman.",
      },
      {
        name: "Faizal Hajamohideen ",
        designation: "Assistant Professor,",
        department: "Department of Information and Technology",
        university:
          "University of Technology and Applied Sciences, Sultanate of Oman.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.02.03",
    keywords: "Forensic, Digital forensics, DNA Analysis, Toxicology.",
    abstract:
      "Forensic evidence collection is a significant aspect of criminal investigations and plays an important role in determining the outcome of a case. This paper outlines the various techniques involved in forensic evidence collection, including photography, fingerprint analysis, DNA analysis, impression evidence, ballistics, trace evidence, document examination, digital forensics, toxicology, and arson and explosion investigation. These techniques provide valuable information to support or refute a suspect's involvement in a crime. By combining these methods, forensic experts can build a comprehensive picture of the evidence at a crime scene and present it in a court of law.",
    published: "2023-05-20",
    source: "Vol 01. Issue. 02 May (2023)",
    cite: "Raida Said Salim Al Abaidani, Faizal Hajamohideen, “Techniques Involved in Forensic Evidence Collections: A Short Communication”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 02, pp. 26–33, May 2023.",
  },
];

function ArchivedIssues2() {
  const id = 2;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
  ];

  return (
    <>
      <Header idPath="true" />
      <Container>
        <Row className="pb-2">
        <h3 className="section-heading">{archiveData[id - 1].volumeNumber}</h3>
        </Row>
        <Row className="pb-4">
          <Col lg={3}>
            <Link to="/">
              <img
                src={`${basePath}/images/journal-cover-issue2.png`}
                alt="Journal Thumbnail"
                className="journal-thumbnail"
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
            <p><span style={{fontWeight: "bold"}}>PUBLISHED:</span> 2023-05-20</p>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 pb-5">
        <Row>
          <Col>
            <h2 className="sub-heading m-0">Papers</h2>
            <hr className="heading-line" />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className="flex-style paper-info"
                  rel="noopener noreferrer"
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className="paper-author"
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className="flex-style py-2"
                  rel="noopener noreferrer"
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className="download-button">
                    <Button
                      variant="secondary"
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=""
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className="flex-style"
                style={{ alignItems: "start" }}
              >
                <p key={i} className="page-number">
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues2;
