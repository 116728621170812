import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";
import "./Abstracting.css";

function AbstractingandIndexing() {
  return (
    <>
      <Header />
      <Container>
        <Row style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <img
            src="./images/Google_Scholar.png"
            alt=""
            className="abstract-logos"
            style={{height: "67px", width: "auto"}}
          />
        </Row>
        <Row style={{ marginBottom: "1rem" }}>
          <img
            src="./images/Cross_Ref.png"
            alt=""
            className="abstract-logos"
            style={{width: "auto"}}
          />
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default AbstractingandIndexing;
