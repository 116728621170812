import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 115,
    name: "Investigating the Optimal K Value in K-Nearest Neighbors and Cost Matrix Analysis for the Global Air Quality Dataset",
    pageNumber: "1-20",
    authorData: [
      {
        name: "Sivachandar Kasiviswanathan",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "RMK College of Engineering and Technology, Chennai, India.",
      },
      {
        name: "Thulasi Bai Vijayan",
        designation: "Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university: "KCG College of Technology, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.05.01",
    keywords:
      "Air Quality Index (AQI), Cost Matrix, Cost Sensitive Classifier, KNN, Machine Learning, Misclassification Cost",
    abstract:
      "Air is indispensable for human life, providing vital oxygen for respiration. Quality air, reflected in the Air Quality Index (AQI), is crucial for optimal health. Exposure to pollutants in compromised air can harm respiratory function, emphasizing the significance of clean air for human well-being. Machine Learning (ML) algorithms significantly enhances human comfort and quality of life across various domains, this study examines the optimal K value within K-Nearest Neighbors (KNN) and conducts a thorough analysis of the cost matrix using different Cost Ratio components for the Global Air Pollution (GAP) Dataset. It emphasizes the importance of determining the optimal K value and considering misclassification costs to enhance the precision of predictions. This study results suggest that a lower number of neighbors contributes to enhanced model accuracy, and the variations in cost components underscore the nuanced impact of misclassification costs on overall performance. The findings emphasize the critical role of k value selection in achieving a balance between model complexity and accuracy, tailored to the specific misclassification cost structure. This study provides valuable insights for fine-tuning the KNN algorithm to optimize outcomes in classification tasks, considering the distinct costs associated with different misclassification types.",
    published: "2023-11-20",
    source: "Vol 01. Issue. 05 Nov (2023)",
    cite: "Sivachandar Kasiviswanathan, Thulasi Bai Vijayan, “Investigating the Optimal K Value in K-Nearest Neighbors and Cost Matrix Analysis for the Global Air Quality Dataset”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 05, pp. 1–20, Nov. 2023. DOI: https://doi.org/10.59567/ktAML.V1.05.01",
  },
  {
    id: 116,
    name: "Revealing the Dynamics: Non-linear Exponential Models for Predicting Sperm Concentration Through Motility and Morphology",
    pageNumber: "21-29",
    authorData: [
      {
        name: "R. Vinoth,",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university: "Arasu Engineering College, Tamilnadu, India.",
      },
      {
        name: "R. Priya",
        designation: "Associate Professor,",
        department: "Department of Bio-Medical Engineering,",
        university:
          "Vels Institute of Science, Technology and Advanced Studies, Tamilnadu, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.05.02",
    keywords:
      "Sperm analysis, Motility, morphology, concentration, Non-linear regression model, Prediction",
    abstract:
      "To determine fertility, the assessment method is strengthened by including the parameters of sperm concentration together with motility and morphology. In the chamber of the hemocytometer, a number of sperm counts show the determination of concentration. Since the fertility of male depends on the evaluation of sperm un-der the terms concentration, motility, and morphology, it is important to analyze their dependency on one another. This paper aims to determine the characteristics of the parameters of a feasible non-linear exponential model for measuring and hence predicting sperm concentration in terms of Morphology, and motility. The non-linear exponential function explained here has four parameters to define the concentration which describes the other terms’ values. This model will help to predict the sperm concentration of different kinds of samples Like samples of Smoking Occ and Alco-holic Occ. The effects and outcomes of the above model analysis are interpreted.",
    published: "2023-11-20",
    source: "Vol 01. Issue. 05 Nov (2023)",
    cite: "R. Vinoth, R. priya, “Revealing the Dynamics: Non-linear Exponential Models for Predicting Sperm Concentration Through Motility and Morphology.”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 05, pp. 21–29, Nov. 2023. DOI: https://doi.org/10.59567/ktAML.V1.05.02",
  },
  {
    id: 117,
    name: "Recognition of Thyroid Disease Using Deep Learning Techniques with Novel CNN Architectures",
    pageNumber: "30-40",
    authorData: [
      {
        name: "P. Kalaiselvi,",
        designation: "Assistant Professor,",
        department: "Department of Artificial Intelligence and Data Science,",
        university: "Sri Sairam Engineering College, Chennai, India.",
      },
      {
        name: "G. Ayyappan",
        designation: "Associate Professor,",
        department: " Department of Computer Science and Engineering,",
        university:
          "Saveetha Institute of Medical and Technical Sciences, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.05.03",
    keywords:
      "ML algorithm, CNN, Thyroid nodules, Deep Learning neural network, ROC, F – Measure.",
    abstract:
      "The most common thyroid cancer or endocrine Malignancy is deadly increasing all over the world. Ultrasound imaging stands as a promising diagnostic method for early thyroid nodule examined. A computer-based diagnostic system has emerged to distinguish between nonthreatening and malicious thyroid nodules. The classifier's inadequacy correlates with the number of features utilized. This article introduces a multi-channel technique involving a convolutional neural network and a feature cropping network. The trending method like regularized leader best deep neural network in Weka 3.85 using deep learning 4J method. We introduce an innovative a carefully designed deep neural network architecture with intricate regularization of the loss function and fine-tuned network hyper parameters, aimed at achieving nodule detection without the need for intricate post-processing refinements. Our local training and validation datasets encompass 3,538 ultrasound frames gathered from 60 and 20 patients, respectively, exhibiting significant variability. The vital part of our approach lies in a deep learning framework rooted in the multi-task model Mask CNN. We have devised a regularization enriched loss function that places a higher emphasis on detection as opposed to segmentation. To assess its efficacy, we conducted validation on 700 UV images from patients. Our model is capable of identifying various types of thyroid nodules. The experimental findings affirm the effectiveness of our proposed method for thyroid nodule detection.",
    published: "2023-11-20",
    source: "Vol 01. Issue. 05 Nov (2023)",
    cite: "P. Kalaiselvi, G. Ayyappan, “Recognition of Thyroid Disease Using Deep Learning Techniques with Novel CNN Architectures”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 05, pp. 30–40, Nov. 2023. DOI: https://doi.org/10.59567/ktAML.V1.05.03",
  },
];

function ArchivedIssues5() {
  const id = 5;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
    {
      id: 105,
      imageName: "journal-cover-issue5.png",
      volumeNumber: "Vol. 01, Issue. 05, Nov (2023)",
    },
  ];

  return (
    <>
      <Header idPath='true' />
      <Container>
        <Row className='pb-2'>
          <h3 className='section-heading'>
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className='pb-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src={`${basePath}/images/journal-cover-issue5.png`}
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2023-11-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='pt-3 pb-5'>
        <Row>
          <Col>
            <h2 className='sub-heading m-0'>Papers</h2>
            <hr className='heading-line' />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className='flex-style paper-info'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className='paper-author'
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className='flex-style py-2'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className='download-button'>
                    <Button
                      variant='secondary'
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=''
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className='flex-style'
                style={{ alignItems: "start" }}
              >
                <p key={i} className='page-number'>
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues5;
