import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";
import "./Contact.css";

function ContactPage() {
  return (
    <div>
      <Header />
      <Container>
        <Row>
          <h3 className="section-heading">Contact</h3>
        </Row>
        <Row>
        <h6 style={{fontWeight: "700", fontSize: "18px", marginBottom: "1.5rem"}}>Knowledge Transactions on Applied Machine learning (ktAML) - eISSN: 2972-3817</h6>
        </Row>
        <Row>
          <Col>
            <p style={{fontWeight: "700", marginBottom: "0.5rem", fontSize: "17px"}}>Dr .T. Vijayan,</p>
            <p style={{marginBottom: "0.5rem"}}>Associate Editor,</p>
            <p style={{marginBottom: "0.5rem"}}>Decision Tree Private Ltd,</p>
            <p style={{marginBottom: "0.5rem"}}>Singapore.</p>
            <p style={{marginBottom: "0.5rem"}}> <span>E-mail: </span><a href="mailto:editor@decisiontreekt.com">editor@decisiontreekt.com</a></p>
          </Col>
        </Row>
        <Row className="py-4">
        <hr />
          <Col>
            <p style={{fontWeight: "700", marginBottom: "0.5rem", fontSize: "17px"}}>Mr. Mohamed Ali Safir Ahamed
,</p>
<p style={{marginBottom: "0.5rem"}}>Managing Director,</p>
            <p style={{marginBottom: "0.5rem"}}>Decision Tree Private Ltd,</p>
            <p style={{marginBottom: "0.5rem"}}>Singapore.</p>
            <p style={{marginBottom: "0.5rem"}}> <span>E-mail: </span><a href="mailto:md@decisiontreekt.com">md@decisiontreekt.com</a></p>
          </Col>
        </Row>
        <hr />
      </Container>
      <Footer />
    </div>
  );
}

export default ContactPage;
