import "./Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import pdfIcon from "./papers/pdf.svg";

export const papersData = [
  {
    id: 130,
    name: "Social Engineering with the Rough Set Theory",
    pageNumber: "1-7",
    authorData: [
      {
        name: "M. Gayathri,",
        designation: "Assistant Professor,",
        department: "Department of Mathematics,",
        university:
          "Queens College of Arts and Science for Women, Pudukkottai, Tamil Nadu, India",
      },
      {
        name: "M. Uma Maheswari",
        designation: "Assistant Professor,",
        department: "Department of Mathematics,",
        university:
          "V.V. Vanniaperumal College for Women, Virudhunagar, Tamil Nadu, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.05.01",
    keywords:
      "Rough set theory, uncertainty, pattern recognition, cluster analysis, social engineering.",
    abstract:
      "This paper explores applying rough set theory and cluster analysis to study social engineering, particularly focusing on identifying community patterns and differences. Social engineering, in this context, refers to the manipulation and influence of individuals or groups to achieve specific outcomes, often through deception. By employing rough set theory, we handle imprecise or incomplete data, which is common in real-world social dynamics. Cluster analysis is then used to group communities with similar attributes, enabling the identification of shared characteristics and anomalies. This approach aims to provide deeper insights into how social engineering tactics are applied across different community structures, helping to develop more robust countermeasures. The findings contribute to both the theoretical understanding of social manipulation and practical interventions to protect vulnerable populations.",
    published: "2024-09-20",
    source: "Vol 02. Issue. 05 Sep (2024)",
    cite: "M. Gayathri, M. Uma Maheswari, “Social Engineering with the Rough Set Theory”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 05, pp. 1–7, Sep. 2024. DOI: https://doi.org/10.59567/ktAML.V2.05.01",
  },
  {
    id: 131,
    name: "SMILES2vec with Attention Mechanisms for Pharmacological Classification and Regression",
    pageNumber: "08-14",
    authorData: [
      {
        name: "S. Chandirasekar,",
        designation: "Assistant Professor,",
        department: "Department of Nano Science,",
        university: "PSG college of Technology, Coimbatore, Tamil Nadu, India",
      },
      {
        name: "M. Boopathy",
        designation: "Assistant Professor,",
        department: "Department of Chemistry,",
        university:
          "Annamalai University, Post graduate Extension Centre, Villupuram, Tamil Nadu, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.05.02",
    keywords:
      "SMILES, Mean Absolute Error, attention mechanism, deep learning, pharmacology, molecular property prediction, LSTM, classification, regression.",
    abstract:
      "Predicting the pharmacological activity of compounds is critical in drug discovery. The pIC50 value, indicative of compound potency, serves as a key metric. Traditional methods rely heavily on experimental data, which are costly and time-consuming. Recent advances in deep learning, particularly in embedding molecular representations like SMILES (Simplified Molecular Input Line Entry System), have shown promise in         predictive pharmacology. This study explores the use of a neural network model, SMILES2vec, enhanced with     attention mechanisms to predict pIC50 values from SMILES strings, addressing both classification and regression tasks.  We present a dual-task learning framework where the model concurrently classifies compounds into pIC50 bins and predicts their exact values. The model achieved high classification accuracy (95.76%) and low mean absolute error (MAE) for regression (0.24), demonstrating its efficacy in predicting compound potency.",
    published: "2024-09-20",
    source: "Vol 02. Issue. 05 Sep (2024)",
    cite: "S. Chandirasekar, M. Boopathy,“ SMILES2vec with Attention Mechanisms for Pharmacological Classification and Regression”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 05, pp. 08–14, Sep. 2024. DOI: https://doi.org/10.59567/ktAML.V2.05.02",
  },
  {
    id: 132,
    name: "Adaptive Cellular Memory in Stress Response: Analyzing HSP70 Expression under Repeated Heat Shock",
    pageNumber: "15-18",
    authorData: [
      {
        name: "P. Ramesh Babu,",
        designation: "Professor,",
        department:
          "Center for Materials Engineering and Regenerative Medicine,",
        university:
          "Bharath Institute of Higher Education and Research, Tamil Nadu, India.",
      },
      {
        name: "K. Prabhu",
        designation: "Professor,",
        department: "Department of Anatomy,",
        university:
          "Sree Balaji Medical College and Hospital, Tamil Nadu, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.05.03",
    keywords:
      "Cellular Memory, HSP70, Gene Expression, Heat Shock Proteins, Stress Response, Computational biology, Adaptive Memory.",
    abstract:
      "Cellular memory hypothesizes that cells retain biochemical traces of past events, influencing their responses to repeated stimuli. This study examines cellular memory by analyzing changes in HSP70 gene expression following consecutive heat shocks, investigating how cellular adaptation mechanisms influence stress-response proteins. Our proposed computational framework includes repeated stimulus protocols, data acquisition, and a Python-based analysis of gene expression data. Results show an enhanced HSP70 response during the second heat shock, suggesting an adaptive, memory-like behavior that may inform medical applications in fields like oncology and immunology, where cellular memory mechanisms can aid in developing resilient treatments.",
    published: "2024-09-20",
    source: "Vol 02. Issue. 05 Sep (2024)",
    cite: "P. Ramesh Babu, K. Prabhu, “Adaptive Cellular Memory in Stress Response: Analyzing HSP70 Expression under Repeated Heat Shock”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 05, pp. 15-18, Sep. 2024. DOI: https://doi.org/10.59567/ktAML.V2.05.03",
  },
];

function Papers() {
  const basePath = window.location.origin;
  return (
    <Container className="pt-3 pb-5">
      <Row>
        <Col>
          <h2 className="sub-heading m-0">Papers</h2>
          <hr className="heading-line" />
        </Col>
      </Row>
      {papersData.map(function (paper, i) {
        return (
          <Row style={{ paddingBottom: "2.25rem" }}>
            <Col lg={11}>
              <Link
                key={paper.id}
                to={`/paper/${paper.id}`}
                target={"_blank"}
                className="flex-style paper-info"
                rel="noopener noreferrer"
                style={{ justifyContent: "start", textDecoration: "none" }}
              >
                {paper.name}
              </Link>
              {paper.authorData.map((data) => {
                return <span className="paper-author" style={{lineHeight: "36px"}}>{data.name}&nbsp;&nbsp;</span>;
              })}

              <Link
                to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                target={"_blank"}
                className="flex-style py-2"
                rel="noopener noreferrer"
                style={{ justifyContent: "start", textDecoration: "none" }}
              >
                <span className="download-button">
                  <Button
                    variant="secondary"
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      padding: "3px 6px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={pdfIcon}
                      alt=""
                      style={{ width: "25px", margin: "0 5px" }}
                    />{" "}
                    Download
                  </Button>{" "}
                </span>
              </Link>
            </Col>
            <Col lg={1} className="flex-style" style={{ alignItems: "start" }}>
              <p key={i} className="page-number">
                pp. {paper.pageNumber}
              </p>
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}

export default Papers;
