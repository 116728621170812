import { Button, Form, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { useState } from "react";

function NavComponent() {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <>
      <Navbar variant="light" style={{ paddingTop: "0.5rem" }}>
        <Container>
          <Nav className="me-auto">
            <NavDropdown
              title="About"
              id="basic-nav-dropdown"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <NavLink to="/scope" className="dropdown-item">
                Scope
              </NavLink>
              <NavLink to="/authors" className="dropdown-item">
                Editorial Team
              </NavLink>
              <NavLink to="/abstracting-and-indexing" className="dropdown-item">
                Abstracting & Indexing
              </NavLink>
              <NavLink to="/privacy" className="dropdown-item">
                Privacy Statement
              </NavLink>
              <NavLink to="/contact" className="dropdown-item">
                Contact
              </NavLink>
            </NavDropdown>
            <NavLink to="/current-issues" className="nav-link">
              Current Issues
            </NavLink>
            <NavLink to="/archives" className="nav-link">
              Archives
            </NavLink>
            <NavLink to="/submission" className="nav-link">
              Submission
            </NavLink>
            <NavLink to="/guidelines-for-authors" className="nav-link">
              Guidelines for Authors
            </NavLink>
          </Nav>
          <Form className="d-flex" style={{visibility: "hidden"}}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="primary">Search</Button>
          </Form>
        </Container>
      </Navbar>
    </>
  );
}

export default NavComponent;
