import { Container, Row, Col } from "react-bootstrap";
import "./Home.css";
import Header from "../../BlockComponents/Header/Header";
import { Link } from "react-router-dom";
import Footer from "../../BlockComponents/Footer/Footer";
import Papers from "../../BlockComponents/Papers/Papers";

function HomePage() {
  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col>
            <h2 className='section-heading m-0'>About the Journal</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Almost all the disciplines are integrated and benefited with
              Machine Learning principles and techniques and invite a platform
              to exchange the results on theory and practice. There is a strong
              evidence and expectation to eliminate the gap between theory and
              experimental approaches. Application and product development can
              be reported to accelerate the growth qualitatively as well
              quantitatively.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>KtAML</span> is an Open
              Access Journal. Online readers don't have to pay any fee.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <h2 className='section-heading m-0' style={{ paddingBottom: 0 }}>
              Current Issue
            </h2>
            <hr className='heading-line' style={{ width: "145px" }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h6
              className='m-0'
              style={{ paddingBottom: 0, fontWeight: "bold" }}
            >
              Vol 02. Issue. 05 Sep (2024)
            </h6>
          </Col>
        </Row>
        <Row className='py-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src='./images/journal2-issue5.png'
                alt='Journal Thumbnail'
                className='journal-thumbnail'
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>PUBLISHED: 2024-09-20</p>
          </Col>
        </Row>
      </Container>
      <Papers currentIssue='true' />
      <Footer />
    </>
  );
}

export default HomePage;
