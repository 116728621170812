import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 112,
    name: "Exploring the Gradient Boosting and LSTM for Power Distribution-based Time Series Analysis",
    pageNumber: "1-10",
    authorData: [
      {
        name: "M. Sudha,",
        designation: "Research Scholar,",
        department: "Department of Mathematics,",
        university: "AMET university, Chennai, India.",
      },
      {
        name: "S. Mehertaj",
        designation: "Assistant Professor,",
        department: "Department of Mathematics,",
        university: "AMET university, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.04.01",
    keywords:
      "XGBoost, LightGBM, LSTM, RMSE, Power Production, Time series Analysis",
    abstract:
      "Electrical energy production is an important function in a country that has abundant demand for society. Therefore, power supply forecasting is very significant to keep the balance of power consumption and production and should not be in short supply at any cost.  Thus, Forecasting and modelling time series analysis of power production becomes important in prediction. We explore the synergy between Gradient Boosting and Long Short-Term Memory (LSTM) networks in the context of time series analysis. Specifically, we investigate how XGBoost and LightGBM, well-known gradient boosting frameworks, complement LSTM, a recurrent neural network architecture. By combining these methods, we aim to uncover deeper insights and elevate predictive potential for time series forecasting.  In this paper, we examine the machine learning methods of forecasting the power generation of 33-year time series data. In the conclusion, we put forward a comparative study between the outcomes attained from the use of LSTM and Gradient-boosting tree-based algorithms XGBoost and LightGBM. The results show that the XGBoost model outclasses the other models with a low error value in forecasting power generation.",
    published: "2023-09-20",
    source: "Vol 01. Issue. 04 Sep (2023)",
    cite: "M. Sudha, S. Mehertaj, “Exploring the Gradient Boosting and LSTM for Power Distribution-based Time Series Analysis”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 04, pp. 1–10, Sep. 2023. DOI: https://doi.org/10.59567/ktAML.V1.04.01",
  },
  {
    id: 113,
    name: "Effects of data augmentation by replicating instances : Classification performance by Decision trees",
    pageNumber: "11-20",
    authorData: [
      {
        name: "C. Raghavendra,",
        designation: "Associate Professor,",
        department: "Department of Emerging Technologies,",
        university: "CVR College of Engineering, Hyderabad, India.",
      },
      {
        name: "G. Gnana Priya",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering",
        university: "Ramco Institute of Technology, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.04.02",
    keywords: "Diabetes, Resampling, J48, Replication, Data Augmentation",
    abstract:
      "Numerous medical data have classification problems for it has unbalanced instances. This means the medical data has less number of positive instances because it refers to disease diagnosis. Even the world's common disease diabetes data is unbalanced. We have the machine learning algorithms to combat the imbalanced classification. Among them, resampling is a useful technique that helps to balance the instance based on the classes’ majority and minority by under-sampling and oversampling methods. However, in spite of its circulation, the sampling has issues in the efficient evaluation of small-sized data. This study measured sampling methods and machine learning classifiers of different split percentages using a diabetes dataset which waver units of imbalance and produce better accuracy. The evaluation measure for each replication percentage is calculated and the same is interpreted in the Discussion.",
    published: "2023-09-20",
    source: "Vol 01. Issue. 04 Sep (2023)",
    cite: "C. Raghavendra, G. Gnana Priya, “Effects of data augmentation by replicating instances: Classification performance by Decision trees.”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 04, pp. 11–20, Sep. 2023.DOI:https://doi.org/10.59567/ktAML.V1.04.02",
  },
  {
    id: 114,
    name: "An instance of Satisfiability Problem learnt with Instance based, Decision trees, Naive Bayes",
    pageNumber: "21-30",
    authorData: [
      {
        name: "K. Alice,",
        designation: "Associate Professor,",
        department: "Department of Computer Science and Engineering,",
        university: "SRM Institute of Science and Technology, Chennai, India.",
      },
      {
        name: "K. Surya",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university: "SRM Easwari Engineering College, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.04.03",
    keywords:
      "Satisfiability, Classification Problem, J48, Naïve Bayes, K-Nearest Neighborhood, Python, k-arity majority gate",
    abstract:
      "Though it is not recommended for mining the data sets produced by any closed-form solution, our approach is very much aiming at the standard satisfiability (SAT) or assignment problem in terms of searching for the existence of learning models and their performance using machine learning algorithms. Here we claim that we do not apply the analytical or theoretical method in the sense it is not based on conventional or well-established minimization rules or circuit synthesis in mind but the application of computational codes for prediction for the given Boolean circuits. The main objectives are to get classification on mapping Boolean literal values to some predefined functional output and to get maximum accuracy of such classifiers. Here we have enumerated the results up to 20 bits majority operator as an instance for constructing the learning models based on machine learning algorithms. The similarity and significance of the results are shown and thus it may open the possibility for extensibility and generalizability to other types of Boolean circuits. The maximum average performance is found to be 80.72% of accuracy and 74.81% of precision based on the selected set of classifiers.",
    published: "2023-09-20",
    source: "Vol 01. Issue. 04 Sep (2023)",
    cite: "K. Alice, K. Surya, “An instance of Satisfiability Problem learnt with Instance based, Decision trees, Naive Bayes”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 04, pp. 21–30, Sep. 2023. DOI: https://doi.org/10.59567/ktAML.V1.04.03",
  },
];

function ArchivedIssues4() {
  const id = 4;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
  ];

  return (
    <>
      <Header idPath='true' />
      <Container>
        <Row className='pb-2'>
          <h3 className='section-heading'>
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className='pb-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src={`${basePath}/images/journal-cover-issue4.png`}
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2023-09-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='pt-3 pb-5'>
        <Row>
          <Col>
            <h2 className='sub-heading m-0'>Papers</h2>
            <hr className='heading-line' />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className='flex-style paper-info'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className='paper-author'
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className='flex-style py-2'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className='download-button'>
                    <Button
                      variant='secondary'
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=''
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className='flex-style'
                style={{ alignItems: "start" }}
              >
                <p key={i} className='page-number'>
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues4;
