import {Col, Container, Row } from "react-bootstrap";
import "./Header.css";
import NavComponent from "../Navbar/Navbar";
import { Link } from "react-router-dom";

function Header(idPath) {
  return (
    <>
      <Container style={{ height: "11rem", padding: "1rem 0" }}>
        <Row className="header-row">
          <Col lg={2}>
            <Link to="/">
              <img src={idPath ? "./../images/logo.png" : "./images/logo.png"} alt="" className="page-logo" />
            </Link>
          </Col>
          <Col
            lg={6}
            className="logo-info"
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontSize: "22px",
                marginTop: "1rem",
                marginBottom: "0.33rem",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
              }}
            >
              Knowledge Transactions on
            </p>
            <h3 style={{ marginBottom: "0", fontWeight: "800", fontSize: "30px" }}>
              Applied Machine Learning
            </h3>
          </Col>
          {/* <Col
            lg={4}
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              padding: "1rem 0"
            }}
          >
            <div className="login-buttons">
              <Button variant="primary" className="login-button">
                Register
              </Button>{" "}
              <Button variant="primary" className="login-button">
                Login
              </Button>{" "}
            </div>
          </Col> */}
        </Row>
      </Container>
      <NavComponent />
    </>
  );
}

export default Header;
