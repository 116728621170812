import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 124,
    name: "Evaluation of Gamification Learning by Text Mining",
    pageNumber: "1-11",
    authorData: [
      {
        name: "K. Sankara Narayanan,",
        designation: "Principal,",
        department: "Sree Gokulam Public School,",
        university: "Chengalpattu, India.",
      },
      {
        name: "S. Meher Taj",
        designation: "Assistant Professor,",
        department: "Department of Mathematics, ",
        university: "AMET University",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.03.01",
    keywords:
      "Gamification, pedagogy, NLP, Sentiment Analysis, game element, RECC, Opinions, Tweeter Analyser, Random Forest, KNN, SVM",
    abstract:
      "There are two ways to find pedagogy with gamification based on game environment directly or with game elements indirectly. Game elements such as Reward, Enjoyment, Competition, and Challenges (RECC) are selected and allowed inside the pedagogy in the hope to improve the performance of the learners. Another approach is to allow gaming environment itself as a part of pedagogy. In both the cases either direct or virtual ICT based class room instructions, the gaming approaches can be applied. This article discovers gamification tools in both education and environment services for engaging students. Here the former approach is proposed with four game elements and applied for the under graduate students doing “Natural language Processing” course in fifth semester. Their opinions were collected and filtered for selection of bag of words and transformation is to lower vector dimension. The polarities of end results either positive or negative are measured through the performance metrics of the models and the results are shown that the Random Forest produced greater accuracy for all the RECC elements, around 93%. Nowadays with the rising reputation for gamification in almost all industries, Education Pedagogy has also been benefited. The main aim of this work is to establish more convincing evidence for causal links between gamification interventions and learning outcomes based on student interest, motivation, satisfaction, participation, perception and pedagogical affects.",
    published: "2024-05-20",
    source: "Vol.02, Issue. 03, May(2024)",
    cite: "K. Sankara Narayanan, S. Meher Taj, “Evaluation of Gamification Learning by Text Mining”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 03, pp. 1–11, May. 2024. DOI: https://doi.org/10.59567/ktAML.V2.03.01",
  },
  {
    id: 125,
    name: "Artificial Intelligence based Non-Invasive Pre-Diagnostic Screening Test for Anaemia using Conjunctiva Digital Images",
    pageNumber: "12-21",
    authorData: [
      {
        name: "Sivachandar Kasiviswanathan,",
        designation: "Assistant Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "RMK College of Engineering and Technology, Chennai, India.",
      },
      {
        name: "Thulasi Bai Vijayan",
        designation: "Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university: "KCG College of Technology, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.03.02",
    keywords:
      "Artificial Intelligence (AI), Haemoglobin, Eyelids, Medical diagnostic imaging, Computer aided diagnosis.",
    abstract:
      "Anaemia is a condition predominantly caused by nutritional deficiency affecting people around the world. One of the traditional diagnostic methods for this condition is through measurement of haemoglobin values using either an invasive or a non-invasive technique. The proposed work converts any consumer-grade camera as a non- invasive pre-diagnostic screening tool for detecting the presence of anaemia. An Artificial Intelligence(AI) model based on Random Forest Algorithm (RFA) was used to classify the samples to be anaemic or non-anaemic. The main input features of this model are the eye images taken from consumer- grade cameras in non-ideal lighting conditions; specifically, the pixel characteristics of conjunctiva region in RGB, HSV and L*a*b* channels. The model has been trained, validated and tuned by adding more input samples and more features. Participant’s basic details like age, sex, height, weight were used as additional input features. This AI based approach produced interesting results with 98.64% sensitivity, 96.74% specificity and accuracy of 97.67%(+/-1.61%) in anaemia detection without any additional equipment in an uncontrolled environment. This research work will enable common people with access to a smartphone camera to be able to screen anaemia periodically in a few easy steps and thereby helping in early detection of anaemia to avoid further complications.",
    published: "2024-05-20",
    source: "Vol.02, Issue. 03, May(2024)",
    cite: "Sivachandar Kasiviswanathan, Thulasi Bai Vijayan, “Artificial Intelligence based Non-Invasive Pre-Diagnostic Screening Test for Anaemia using Conjunctiva Digital Images”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 03, pp. 12–21, May 2024. DOI: https://doi.org/10.59567/ktAML.V2.03.02",
  },
  {
    id: 126,
    name: "Evaluating Non-linear Models for Predicting Sperm Motility: A Study on Concentration and Morphology",
    pageNumber: "22-28",
    authorData: [
      {
        name: "M. Sindhuja,",
        designation: "Assistant Professor,",
        department: "Department of Computing Technologies,",
        university: "SRMIST, Chennai",
      },
      {
        name: "S. Priya",
        designation: "Assistant Professor,",
        department: "Department of Computing Technologies,",
        university: "SRMIST, Chennai",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V2.03.03",
    keywords:
      "Sperm analysis, Motility, morphology, concentration, Non-linear exponential model, Prediction.",
    abstract:
      "Determined poor motility predicts upright miscarriage in fertilization which helps to make decisions in treatment. To define fertility, the assessment method is strengthened by including the parameters of sperm concentration together with motility and morphology. In the chamber of the hemocytometer, several sperm counts show the determination of concentration. Since the fertility of male depends on the evaluation of sperm under the terms concentration, motility and morphology, it is important to analyze their dependency on one another. This paper aims to determine the characteristics of the parameters of a feasible non-linear exponential model for measuring and hence predicting sperm motility in terms of Morphology, and concentration. The non-linear exponential function explained here has four parameters to outline the motility which describes the other terms’ values. This model will help to predict the sensitivity of sperm of different kinds of samples Like samples of Smoking Occ and Alcoholic Occ. The outcomes of the above model analysis are construed and questioned.",
    published: "2024-05-20",
    source: "Vol.02, Issue. 03, May(2024)",
    cite: "M.Sindhuja, S. Priya , “Evaluating Non-linear Models for Predicting Sperm Motility: A Study on Concentration and Morphology”, Knowledge Transactions on Applied Machine Learning, Vol. 02, Issue. 03, pp. 22-28, May. 2024. DOI: https://doi.org/10.59567/ktAML.V2.03.03",
  },
];

function ArchivedIssues8() {
  const id = 8;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
      id: 103,
      imageName: "journal-cover-issue3.png",
      volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
    },
    {
      id: 104,
      imageName: "journal-cover-issue4.png",
      volumeNumber: "Vol. 01, Issue. 04, Sep (2023)",
    },
    {
      id: 105,
      imageName: "journal-cover-issue5.png",
      volumeNumber: "Vol. 01, Issue. 05, Nov (2023)",
    },
    {
      id: 106,
      imageName: "journal2-issue1.png",
      volumeNumber: "Vol.02, Issue 01, Jan(2024)",
    },
    {
      id: 107,
      imageName: "journal2-issue2.png",
      volumeNumber: "Vol.02, Issue. 02, Mar(2024)",
    },
    {
      id: 108,
      imageName: "journal2-issue3.png",
      volumeNumber: "Vol.02, Issue. 03, May(2024)",
    },
  ];

  return (
    <>
      <Header idPath='true' />
      <Container>
        <Row className='pb-2'>
          <h3 className='section-heading'>
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className='pb-4'>
          <Col lg={3}>
            <Link to='/'>
              <img
                src={`${basePath}/images/journal2-issue3.png`}
                alt='Journal Thumbnail'
                className='journal-thumbnail'
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2024-05-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className='pt-3 pb-5'>
        <Row>
          <Col>
            <h2 className='sub-heading m-0'>Papers</h2>
            <hr className='heading-line' />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className='flex-style paper-info'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className='paper-author'
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className='flex-style py-2'
                  rel='noopener noreferrer'
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className='download-button'>
                    <Button
                      variant='secondary'
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=''
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className='flex-style'
                style={{ alignItems: "start" }}
              >
                <p key={i} className='page-number'>
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues8;
