import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

function PrivacyPage() {
  return (
    <>
      <Header />
      <Container
        style={{ textAlign: "left", margin: "0 1.5rem", paddingBottom: "15rem" }}
      >
        <Row>
          <Col>
            <h2 className="section-heading m-0">Privacy Statement</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              The personal details with names and email addresses entered in
              this journal site will be treated exclusively for the stated
              purposes of this journal and will not be made available for any
              other purpose or to any other party.
            </p>
            <p>
              If you have any questions regarding this policy or your personal
              information, we can be contacted via email.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default PrivacyPage;
