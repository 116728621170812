import { Container, Row } from "react-bootstrap";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const basePath = window.location.origin;

  return (
    <>
      <Container fluid>
        <Row className='footer-text'>
          <p style={{ fontWeight: "bold", fontSize: "20px" }}>
            Knowledge Transactions on Applied Machine Learning (ktAML) – eISSN:
            2972-3817
          </p>
          <p>
            <strong>Indexed</strong> in Google Scholar, CrossRef
          </p>
          <p>
            <strong>Plagiarism check </strong> by Turnitin.  
            <strong>Published</strong> under CC-BY 4.0.
          </p>
          <p>
            <img
              src={`${basePath}/images/Google_Scholar.png`}
              alt=''
              style={{ width: "auto", height: "25px", padding: "0 0.75rem" }}
            />{" "}
            <img
              src={`${basePath}/images/Cross_Ref.png`}
              alt=''
              style={{ width: "auto", height: "40px", padding: "0 0.75rem" }}
            />{" "}
            <Link to='./Creative-Commons.pdf' target='_blank'>
              <img
                src={`${basePath}/images/cc.png`}
                alt=''
                style={{ height: "45px", padding: "0 0.75rem" }}
              />
            </Link>
          </p>
          <p>
            Planned for Indexing in Elsevier Scopus, Ei Compendex, IET Inspec,
            EBSCO, DOAJ, dblp and LearnTechLib.
          </p>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
