import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../BlockComponents/Papers/Papers.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import pdfIcon from "./pdf.svg";
import Footer from "../../BlockComponents/Footer/Footer";
import Header from "../../BlockComponents/Header/Header";

const papersData = [
  {
    id: 109,
    name: "Detection of Fake Documents by Tree-Based Machine Learning Algorithms",
    pageNumber: "1-17",
    authorData: [
      {
        name: "Yong Yik Loong,",
        designation: "Head of the Department,",
        department: "Faculty of Computing and Digital Technology,",
        university: "School of ICT, Help University, Malaysia.",
      },
      {
        name: "Christine Lee",
        designation: "Dean,",
        department: "School of Computing,",
        university: "First city University, Malaysia",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.03.01",
    keywords:
      "SPAM, HAM, Text classification, Machine learning, Feature selection",
    abstract:
      "SMS, which stands for Short Message Service, is a form of text-based communication that enables mobile phone users to exchange concise messages. It is estimated that approximately 72.6% of all mobile phone subscribers as of the end of 2022 utilize SMS as a means of communication. However, with the increased usage of mobile phones, the prevalence of spam SMS has also risen over the past few years. To address this issue, numerous techniques have been developed to identify and mitigate the impact of spam SMS messages. The focus of this paper is to assess the effectiveness of various decision tree algorithms in classifying spam SMS messages, with the ultimate goal of resolving spam-related problems. SMS spam collection has been taken as a case study to do an experiment as well as we compared the tree-based machine learning algorithms like J48, LMT, and Random Tree. The experimental results of evaluation metrics like F-measures, Precision, and recall showed which algorithm is more effective in classifying spam SMS. The Proposed method obtained the highest accuracy of 95.04 % by J48 Classifier.",
    published: "2023-07-20",
    source: "Vol 01. Issue. 03 Jul (2023)",
    cite: "Yong yik loong, Christine Lee, “Detection of Fake Documents by Tree-Based Machine Learning Algorithms”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 03, pp. 1–17, Jul. 2023. DOI: https://doi.org/10.59567/ktAML.V1.03.01",
  },
  {
    id: 110,
    name: "Ensembles of Decision Rules to Predict Brake thermal efficiency of Bio Diesel with Nanoparticles",
    pageNumber: "18-29",
    authorData: [
      {
        name: "R.J. Golden Renjith Nimal,",
        designation: "Associate Professor,",
        department: "Department of Mechanical Engineering,",
        university:
          "Jai Shriram Engineering College, Tirupur, Tamil Nadu, India",
      },
      {
        name: "S. Jacob,",
        designation: "Assistant Professor,",
        department: "Department of Automobile Engineering,",
        university:
          "Vels Institute of Science, Technology & Advanced Studies (VISTAS), Chennai, India",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.03.02",
    keywords:
      "Brake Thermal Efficiency, Decision Rules, Bio Diesel, Nano Particles",
    abstract:
      "This scientific study focuses on the prediction of brake thermal efficiency (BTE) in the context of biodiesel with nanoparticles containing aluminium oxide (Al2O3), titanium dioxide (TiO2), and copper oxide (CuO).  Ensembles of decision tree classifiers are employed as a predictive modelling approach to estimate BTE based on nanoparticle composition. Experimental data pertaining to biodiesel compositions and corresponding BTE values are utilized to train and validate the ensemble models. The outcomes for this type of analysis generate insight for the potential of nanoparticle-based biodiesel blends to optimize engine performance. However, limitations such as the complexity of nanoparticle interactions and the need for further validation with real-world engine testing should be considered.",
    published: "2023-07-20",
    source: "Vol 01. Issue. 03 Jul (2023)",
    cite: "R.J. Golden Renjith Nimal, S. Jacob, “Ensembles of Decision Rules to Predict Brake thermal efficiency of Bio Diesel with Nanoparticles”, Knowledge Transactions on Applied Machine Learning, Vol. 01, Issue. 03, pp. 18–29, Jul. 2023.DOI: https://doi.org/10.59567/ktAML.V1.03.02",
  },
  {
    id: 111,
    name: "Contribution by Supervised Learners on Outcome Based Education in Revised Education Policy using Machine Algorithms",
    pageNumber: "30-42",
    authorData: [
      {
        name: "Kalaiselvi B,",
        designation: "Associate Professor,",
        department: "Department of Electronics and Communication Engineering,",
        university:
          "Bharath Institute of Higher Education and Research, Chennai, India.",
      },
      {
        name: "Sabarish R,",
        designation: "Associate Professor,",
        department: "Department of Mechanical Engineering,",
        university:
          "Bharath Institute of Higher Education and Research, Chennai, India.",
      },
    ],
    doi: "https://doi.org/10.59567/ktAML.V1.03.03",
    keywords:
      "Course outcomes, Program Outcomes, Program Specific Outcomes, Bloom's Taxonomy, OBE system, Logistics, One R, Random Forest, SMO",
    abstract:
      "The Outcome-Based Education (OBE) system in higher education for Engineering programs aims to enhance students' development in cognitive, affective, and psychomotor domains. It focuses on the acquisition of computer skills, organizational skills, leadership skills, self-confidence, creativity, behavioural ethics, work ethics, motivation, flexibility, adaptability, and entrepreneurial skills. This system represents a departure from traditional teaching methods that centered around following a prescribed curriculum and emphasized the mastery of required knowledge, skills, and characteristics. The belief is that by integrating advancements in technology, the quality of education can better prepare students for future challenges. This article highlights a proposal for implementing the Outcome-Based Education system in engineering colleges, endorsed by the Indian agency for NBA accreditations. It recommends the use of a machine-learning approach to assess the attainment of course outcomes. The variations in attaining these outcomes have an impact on program outcomes and program-specific outcomes, which are criteria prescribed by the agency. The article presents a case study of a selected private university, where supervised machine-learning models are developed to predict course outcomes using inputs such as learners' direct and indirect assessments, as well as end-semester examinations. The course coordinator sets threshold parameters for each course's outcomes. The proposed machine-learning framework achieves a maximum accuracy level of 98.18% using the SMO classifier, with an overall high performance level using function-based categorization.",
    published: "2023-07-20",
    source: "Vol 01. Issue. 03 Jul (2023)",
    cite: "Kalaiselvi B, Sabarish R, “Contribution by Supervised Learners on Outcome Based Education in Revised Education Policy using Machine Algorithms”, Knowledge Transactions on Applied Machine Learning, vol. 01, Issue. 03, pp. 30–42, Jul. 2023. DOI: https://doi.org/10.59567/ktAML.V1.03.03",
  },
];

function ArchivedIssues3() {
  const id = 3;
  const [paper, setPaperData] = useState(papersData);
  const basePath = window.location.origin;

  const archiveData = [
    {
      id: 101,
      imageName: "journal-cover.png",
      volumeNumber: "Vol. 01 Issue. 01, Mar (2023)",
    },
    {
      id: 102,
      imageName: "journal-cover-issue2.png",
      volumeNumber: "Vol. 01, Issue. 02, May (2023)",
    },
    {
        id: 103,
        imageName: "journal-cover-issue3.png",
        volumeNumber: "Vol. 01, Issue. 03, Jul (2023)",
      },
  ];

  return (
    <>
      <Header idPath="true" />
      <Container>
        <Row className="pb-2">
          <h3 className="section-heading">
            {archiveData[id - 1].volumeNumber}
          </h3>
        </Row>
        <Row className="pb-4">
          <Col lg={3}>
            <Link to="/">
              <img
                src={`${basePath}/images/journal-cover-issue3.png`}
                alt="Journal Thumbnail"
                className="journal-thumbnail"
                style={{ width: "15rem" }}
              />
            </Link>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <p>
              <span style={{ fontWeight: "bold" }}>PUBLISHED:</span> 2023-07-20
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="pt-3 pb-5">
        <Row>
          <Col>
            <h2 className="sub-heading m-0">Papers</h2>
            <hr className="heading-line" />
          </Col>
        </Row>
        {papersData.map(function (paper, i) {
          return (
            <Row style={{ paddingBottom: "2.25rem" }}>
              <Col lg={11}>
                <Link
                  key={i}
                  to={`/paper/${paper.id}`}
                  target={"_blank"}
                  className="flex-style paper-info"
                  rel="noopener noreferrer"
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  {paper.name}
                </Link>
                {paper.authorData.map((data) => {
                  return (
                    <span
                      className="paper-author"
                      style={{ lineHeight: "36px" }}
                    >
                      {data.name}&nbsp;&nbsp;
                    </span>
                  );
                })}

                <Link
                  to={`${basePath}/papers/KTAML-paper-${paper.id}.pdf`}
                  target={"_blank"}
                  className="flex-style py-2"
                  rel="noopener noreferrer"
                  style={{ justifyContent: "start", textDecoration: "none" }}
                >
                  <span className="download-button">
                    <Button
                      variant="secondary"
                      style={{
                        fontSize: "13px",
                        display: "flex",
                        padding: "3px 6px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={pdfIcon}
                        alt=""
                        style={{ width: "25px", margin: "0 5px" }}
                      />{" "}
                      Download
                    </Button>{" "}
                  </span>
                </Link>
              </Col>
              <Col
                lg={1}
                className="flex-style"
                style={{ alignItems: "start" }}
              >
                <p key={i} className="page-number">
                  pp. {paper.pageNumber}
                </p>
              </Col>
            </Row>
          );
        })}
      </Container>
      <Footer />
    </>
  );
}

export default ArchivedIssues3;
